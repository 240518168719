import axios from '@axios'
import { getUserData } from '@/auth/utils'
import { isEmpty } from '@/store/functions'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchUsers(ctx, queryParams) {
      const userData = getUserData()

      return new Promise((resolve, reject) => {
        axios
          .post('', {
            query: `
              {
                allProfiles(
                    first: ${queryParams.perPage},
                    offset: ${queryParams.perPage * (queryParams.page - 1)},
                    username: "${queryParams.q}",
                    client:"${userData.profile.client.id}",
                    isStaff: true,
                    excludeName:${!userData.isSuperuser},
                    orderBy: "${queryParams.sortDesc ? '-' : ''}${queryParams.sortBy}",
                    ${queryParams.status !== null ? 'isActive: ' : ''}${queryParams.status !== null ? queryParams.status.toLowerCase() : ''}
                  ) {
                  totalCount
                  edgeCount
                  edges {
                    node {
                      id
                      username
                      firstName
                      lastName
                      email
                      isActive
                      profileVendor {
                        edges {
                          node {
                            id
                            name
                          }
                        }
                      }
                      customuserPtr {                        
                        groups {
                          edges {
                            node {
                              id
                              name                             
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            `,
          })
          .then(response => {
            const allProfiles = response
            const searchName = response.data.data.allProfiles.edges

            axios
              .post('', {
                // params: queryParams
                query: `
                              {
                                allProfiles(
                                    first: ${queryParams.perPage},
                                    offset: ${queryParams.perPage * (queryParams.page - 1)},
                                    email: "${queryParams.q}",
                                    client:"${userData.profile.client.id}",
                                    isStaff: true,
                                    excludeName:${!userData.isSuperuser},
                                    orderBy: "${queryParams.sortDesc ? '-' : ''}${queryParams.sortBy}",
                                    ${queryParams.status !== null ? 'isActive: ' : ''}${queryParams.status !== null ? queryParams.status.toLowerCase() : ''}
                                  ) {
                                  totalCount
                                  edgeCount
                                  edges {
                                    node {
                                      id
                                      username
                                      firstName
                                      lastName
                                      email
                                      isActive
                                      profileVendor {
                                        edges {
                                          node {
                                            id
                                            name
                                          }
                                        }
                                      }
                                      customuserPtr {                        
                                        groups {
                                          edges {
                                            node {
                                              id
                                              name
                                              
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            `,
              }).then(result => {
                const searchMail = result.data.data.allProfiles.edges
                let nuevo = [...searchName, ...searchMail]

                const hash = {}
                nuevo = nuevo.filter(current => {
                  const exists = !hash[current.node.id]
                  hash[current.node.id] = true
                  return exists
                })

                allProfiles.data.data.allProfiles.edges = nuevo

                resolve(allProfiles)
              }).catch(err => {
                reject(err)
              })
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    fetchUser(ctx, { id }) {
      const userData = getUserData()

      return new Promise((resolve, reject) => {
        axios
          .post('', {
            query: `
              {
                __type(name: "ProfileNode") {
                  fields {
                    name
                    description
                    type {
                      kind
                      ofType {
                        name
                      }
                      name
                      description
                      enumValues {
                        description
                        name
                      }
                    }
                  }
                }
                  allProfiles(id: "${id}",
                  client:"${userData.profile.client.id}"
                  ) {
                    edges {
                      node {
                      id
                        username
                        firstName
                        lastName
                        email
                        isActive
                        dateJoined
                        lastLogin
                        profileVendor {
                          edges {
                            node {
                              id
                              vendorName
                            }
                          }
                        }
                        customuserPtr {
                          id
                          groups {
                            edges {
                              node {
                                id
                                name
                                
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                allGroups {
                  edges {
                    node {
                      id
                      name
                    }
                  }
                }
                allVendors {
                  edges {
                    node {
                      id
                      vendorName                      
                    }
                  }
                }
              }
            `,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchGroups() {
      return new Promise((resolve, reject) => {
        axios
          .post('', {
            query: `
              query
            {
              allGroups {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
            }
            `,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    mutateUser(ctx, data) {
      const { user } = data
      const { groups } = data
      const { vendor } = data
      const { categories } = data
      const permisosString = JSON.stringify(groups)

      function getIdArray(array) {
        const res = []
        for (let i = 0; i < array.length; i += 1) {
          res.push(array[i].node.id)
        }
        return res
      }

      return new Promise((resolve, reject) => {
        axios
          .post('', {
            query: `
                mutation {
                  updateCustomuser(id: "${user.id}" input: {
                    groupsAdd: ${permisosString},
                    ${user.password != null && user.password.length !== 0 ? `password: "${user.password}",` : ' '}
                    firstName: "${user.firstName}",
                    lastName: "${user.lastName}",
                    ${user.email !== '' ? `email: "${user.email}", ` : ' '}                            
                  }) {
                    customUser {
                      id
                      username
                      firstName
                      lastName
                      email
                      isActive
                      dateJoined
                      lastLogin
                    
                      groups {
                        edges {
                          node {
                            id
                            name
                      }
                    }
                  }
                }
              }
              ${!isEmpty(vendor) || !isEmpty(categories) ? `pr: updateProfile(id:"${user.id}",input:{
                ${!isEmpty(vendor) ? `profileVendor:"${vendor.id}",` : ''}
                ${!isEmpty(categories) ? `sponsorCategory: [${getIdArray(categories)}]` : ''}}){
                profile{
                  id
                  username
                  profileVendor{
                    edges{
                      node{
                        id
                        vendorName
                      }
                    }
                  }
                }
              }` : ''}
            }
          `,

          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    updateUserGroups(ctx, { customUserId, groups }) {
      const permisosString = JSON.stringify(groups)

      return new Promise((resolve, reject) => {
        axios
          .post('', {
            query: `
              mutation {
              updateCustomuser(id: "${customUserId}" input: { groupsAdd: ${permisosString} }) {
                              customUser {
                  id
                  username
                  firstName
                                groups {
                                  edges {
                                    node {
                        id
                      }
                    }
                  }
                }
              }
            }
            `,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .post('', {
            query: `
              mutation{
  deleteProfile(id: "${id}") {
    found
    deletedId
  }
}
`,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addUser(ctx, data) {
      const json = getUserData()
      const client = json.profile.client.id
      return new Promise((resolve, reject) => {
        axios
          .post('', {
            query: `
            mutation {
              createProfile(input: {
                username: "${data.username}",
                password: "${data.password}",
                firstName: "${data.firstName}",
                lastName: "${data.lastName}",
                email: "${data.email}",
                client: "${client}"
              }) {
                profile {
                    id
                  }
                }
              }
              `,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
